import { Injectable } from "@angular/core";
import { BaseStep } from "./base-step.model";
import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { Observable, of } from "rxjs";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { SenderPatientRelationship } from "../../process/base-request.model";
import { WorkflowItem } from "../../systems/workflow.model";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { CreatePatientRelationshipDataModel } from "../models/create-patient-relationship-data.model";

@Injectable({
    providedIn: 'root',
  })
export class CreatePatientRelationshipStep extends BaseStep<IWorkflowDataState & ICreatePatientRelationshipWorkflowState>{
    constructor(
        protected messageBusService: MessageBusService,
        private workflowService: WorkflowService
    ){
        super(messageBusService);

        this.showHeader = true;
        this.showButtonBack = false;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = this.workflowService.getWorkflowDataState();
    }

    isCompleted(): Observable<boolean> {
        this.showButtonBack = this.stateData.getPatientRelationship() == SenderPatientRelationship.OTRO;
        return of(this.stateData.getUserPhrId() > 0 || this.patientRelationshipSelected());
    }

    showStepInActionBack(): boolean {
        return true;
    }
    
    showStep(): boolean {
        return true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage): void {
        if (message.componentName == ComponentName.CREATE_PATIENT_RELATIONSHIP){
            this.stateData.setPatientRelationship(message.data.patientRelationship);
        }
    }
    protected buildComponentConfigurationData(): { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.CREATE_PATIENT_RELATIONSHIP] = this.getCreatePatientRelationshipModel();

        return formConfigurationData;
    }
    
    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.CREATE_PATIENT_RELATIONSHIP;
    }

    private patientRelationshipSelected():boolean{

        let patientRelationship = this.stateData.getPatientRelationship();

        return patientRelationship > 0 && patientRelationship != SenderPatientRelationship.OTRO;
    }

    private getCreatePatientRelationshipModel():CreatePatientRelationshipDataModel{
        let dataModel = new CreatePatientRelationshipDataModel();

        dataModel.patientRelationship = this.stateData.getPatientRelationship();

        return dataModel;
    }
}

export interface ICreatePatientRelationshipWorkflowState{
    getUserPhrId(): number;
    getPatientRelationship(): number;
    setPatientRelationship(patientRelationshipType:number):void;
}