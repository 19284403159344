import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base-service';
import { Observable } from 'rxjs';
import { AttentionsHistory } from 'src/app/shared/interfaces/attentions-history';
import { MedicalOrder } from 'src/app/shared/interfaces/medical-order';
import { BaseService } from './base.service';
import { MedicalOrderViewModel } from 'src/app/shared/models/clinic-history/medical-order.model';

@Injectable({
  providedIn: 'root'
})
export class MedicalOrderService {

  private url = 'api/ordenes-medicas';

  constructor(
    private http: HttpBaseService,
    private baseService: BaseService
  ) { }

  signupNoVerifiedUser(args: SignupNoVerifiedUserDTO) {
    return this.http.post('api/usuarios/no-verificado', args, null, false);
  }

  get(id: string) {
    const url = `${this.url}/por-verificar/${id}/historial-atencion`;
    return this.http.get<AttentionsHistory>(url);
  }

  getById(id: string): Observable<MedicalOrder> {
    const url = `${this.url}/${id}`;
    return this.http.get<MedicalOrder>(url);
  }

  getList(idPaciente: number, tipos: string) {
    const url = `${this.url}?&idPaciente=${idPaciente}&idTiposSolicitud=${tipos}`;
    return this.http.get<MedicalOrderViewModel[]>(url);
  }
}

export interface MedicalOrderSummary {
  idResponsableServicio: number;
  idTipoSolicitud: number;
  idVerificacion: string;
  nombrePaciente: string;
  nombreResponsableServicio: string;
  ubicacionFoto: string;

  idPaciente?:number;
  idUsuario: number;
  emailUsuario: string;
  idProveedorServicioAutenticacion: number;
}

export interface MedicalOrderValidatedUser extends MedicalOrderSummary {
  emailPaciente: string;
  fechaNacimiento: string;
  isValid: boolean;
  tipoRelacionPacienteUsuario: number;
}

export interface SignupNoVerifiedUserDTO {
  dominioEmpresaPhr: string;
  idEmpresaPHR: number;
  idVerificacionSolicitudPorVerificar: string;
  password: string;
  username: string;
}